















































import Vue from 'vue';
import api from '@/api';
import JobDetails from '@/models/JobDetails';
// components
import JobInviteModal from '@/components/job/JobInviteModal.vue';
import JobHeader from '@/components/modals/JobHeader.vue';
import JobDetailsView from '@/components/job/JobDetailsView.vue';
import JobDetailsSidebar from '@/components/job/JobDetailsSidebar.vue';

interface Data {
    job: JobDetails | null;
    schema: string | null;
}

export default Vue.extend({
    name: 'JobListing',
    components: {
        JobInviteModal,
        JobDetailsView,
        JobDetailsSidebar,
        JobHeader
    },
    data(): Data {
        return {
            job: null,
            schema: null
        }
    },
    methods: {
        closeJobInviteModal() {
            this.$bvModal.hide('job-invite-modal');
        }
    },
    computed: {
        location(): string {
            return this.job == null || this.job.location == null ? '' : `${this.job.location.city}, ${this.job.location.stateOrProvince} ${this.job.location.country}`;
        }
    },
    async mounted() {
        try {
            const jobId = this.$route.params.id;
            const src = this.$route.query.src as string;
            if(typeof src === 'string') {
                sessionStorage.setItem('source', src);

                switch (src) {
                    case 'clickcast':
                    case 'appcast':
                        sessionStorage.setItem('payload', `ccuid=${this.$route.query.ccuid as string}`);
                        break;
                    case 'pando':
                        sessionStorage.setItem('payload', this.$route.query.etd as string);
                        break;
                    case 'joveo':
                        sessionStorage.setItem('payload', JSON.stringify({ 
                                                            jClickId: this.$route.query.jClickId as string,
                                                            id: this.$route.query.id as string,
                                                            c: this.$route.query.c as string,
                                                            a: this.$route.query.a as string
                                                          }));
                        break;
                    case 'jobsplice':
                        sessionStorage.setItem('payload', `jobsplice: ${Date.now.toString()}`);
                        break;

                    case 'indeed':
                        sessionStorage.setItem('payload', `Indeed: ${Date.now.toString()}`);
                        break;

                    case 'jobtarget':
                        sessionStorage.setItem('payload', `JobTarget: ${Date.now.toString()}`);
                        break;

                    case 'jobcase':
                        sessionStorage.setItem('payload', `JobTarget: ${Date.now.toString()}`);
                        break;

                    case 'mjhelper':
                        sessionStorage.setItem('payload', `MyJobHelper: ${Date.now.toString()}`);
                        break;

                    case 'jbio':
                        sessionStorage.setItem('payload', `JobBoardIO: ${Date.now.toString()}`);
                        break;

                    case 'afsite':
                        sessionStorage.setItem('payload', `Affiliate Site: ${Date.now.toString()}`);
                        break;

                    case 'career':
                        sessionStorage.setItem('payload', `Active: ${Date.now.toString()}`);
                        break;

                    case 'gfj':
                        sessionStorage.setItem('payload', `GFJ: ${Date.now.toString()}`);
                        // try {
                        //     const rslt = await this.$http.get(`${api}/jobs/${jobId}/schema`);
                        //     this.schema = JSON.stringify(rslt.data)
                        // } catch (error) {
                        //     // eslint-disable-next-line
                        //     console.error(error);
                        // }
                        break;

                    default:
                        // reference + custom payload
                        sessionStorage.setItem('payload', this.$route.query.tag as string);
                        break;
                }
            } else {
                sessionStorage.setItem('source', 'internal');
            }               

            const rslt = await this.$http.get(`${api}/jobs/campaign/${jobId}`);
            this.job = rslt.data;

            // // reset current company based on current job's company id
            // this.$store.commit('setCurrentOrg', this.job!.companyId);  // TODO
            
        } catch (error) {
            // eslint-disable-next-line
            console.error(error);
        }
    }
});
